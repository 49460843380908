import axios from 'axios';

const baseURL = '/'
// const baseURL = 'https://internal-gate.workspace.kz/';

// eslint-disable-next-line
const env = process.env;
var axiosInstance = axios.create({baseURL});

axiosInstance.addToken = (token, type = 'Bearer') => {
    const authorizationHeaderValue = `${type} ${token}`;
    axiosInstance.defaults.headers.common['Authorization'] =
        authorizationHeaderValue;
    return authorizationHeaderValue;
};

axiosInstance.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        // const originalRequest = error.config;
        // if (
        //     error.response.status === 401 &&
        //     originalRequest.url !== '/api/auth/login'
        // ) {
        //     localStorage.removeItem('token');
        //     window.location = '/login';
        // }

        return Promise.reject(error);
    }
);
export default axiosInstance;
