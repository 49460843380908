const baseUrl = '/api';
export default ($axios) => {
    return {
        index(guid) {
            return $axios.get(`${baseUrl}/documents/${guid}`);
        },
        get_attachments(id) {
            return $axios.get(`${baseUrl}/attachments/${id}`);
        },
        post_contract(){
            return $axios.post(`${baseUrl}/documents`);
        },
        post_attachment(){
            return $axios.post(`${baseUrl}/attachments`);
        },
        notifications(){
            return $axios.post(`${baseUrl}/notifications`);
        },
        cms(){
            return $axios.post(`${baseUrl}/cms`)
        },
        verification(id){
            return $axios.post(`${baseUrl}/verify/${id}`, id);
        },
        get_sign_data(guid){
            return $axios.get(`${baseUrl}/documents/${guid}/sled`, guid);
        },
        sign(payload){
            return $axios.post(`${baseUrl}/documents/sign`, payload);
        },
        sms_code_send(form){
            return $axios.post(`${baseUrl}/documents/${form.guid}/send-sms`, form.form);
        },
        check_sms_code(form){
            return $axios.post(`${baseUrl}/documents/${form.guid}/sign-sms`, {sms_code:form.code})
        }
    };
};
